async function fetchRecruiteeData(url) {
	// Check if the URL is not provided
	if (!url) {
		console.error("No API URL provided.");
		return;
	}

	try {
		// Fetch the main data from the given URL
		const response = await fetch(url);
		const data = await response.json();

		// Initialize arrays for storing jobs and departments
		let jobs = [];
		let departments = [];

		// Loop through each offer in the fetched data
		for (let offer of data.offers) {
			// Construct a job object with necessary details
			let job = {
				id: offer.id,
				url: offer.careers_url,
				title: offer.title,
				location: offer.location,
				department: offer.department,
				company: offer.company_name,
				description: offer.description,
				// Add any other relevant fields here
			};

			// Add the job object to the jobs array
			jobs.push(job);

			// Add the job's department to the departments array
			departments.push(offer.department);
		}

		// Construct the final recruitee object
		let recruitee = {
			jobs: jobs,
			// Remove duplicate department names and convert back to an array
			departments: [...new Set(departments)],
		};

		// Return the final recruitee data object
		return recruitee;
	} catch (error) {
		// Log any errors that occur during the fetching process
		console.error("Error fetching data:", error);

		// Propagate the error if you want to handle it further up the chain
		throw error;
	}
}

function displayJobsByDepartment(wrapper, department, jobsData) {
	// Select the container where the job listings will be displayed
	const jobList = wrapper.find(".job-list");

	// Clear the current list of jobs to prepare for the new list
	jobList.empty();

	// Iterate over each job in the jobsData
	Object.keys(jobsData.jobs).map((jobId, index) => {
		const job = jobsData.jobs[jobId];

		// Check if the job's department matches the specified department
		if (job.department === department) {
			// Append the job card to the jobList container
			jobList.append(createJobCard(job));
		}
	});
}

function createJobCard(job) {
	if (!job) {
		return `
			<div class="job-card"><p class="title">Momentan gibt es keine offenen Stellen in dieser Abteilung.</p></div>
			<p>Du möchtest dich trotzdem bewerben? Bitte sende uns eine <a href="mailto:careers@crowdhouse.ch">Blindbewerbung</a>.</p>
		`;
	} else {
		return `
        <a href="${job.url}" class="job-card">
            <p class="title">${job.title}</p>
            <ul>
                <li><span class='ch-tag-button'>${job.department}</span></li>
                <li><span>${job.location}</span></li>
            </ul>
        </a>`;
	}
}

$(".element-job-listing, .careers-job-board").each(function () {
	let jobsData;

	// Select the job listing and career board elements from the DOM
	const wrapper = $(this);
	const apiUrl = wrapper.attr("data-api");

	if (!apiUrl) {
		console.error("No API URL provided.");
		wrapper.find(".listings").html(createJobCard());
		return;
	}

	(async () => {
		jobsData = await fetchRecruiteeData(apiUrl);

		// Check if the page contains the career board element
		if (wrapper.hasClass("careers-job-board")) {
			// Clear the current content of the career board
			wrapper.empty();

			// Display each department title followed by its jobs
			jobsData.departments.map((department) => {
				wrapper.append(
					`<p class="department-title"><strong>${department}</strong></p>`
				);

				Object.values(jobsData.jobs).forEach((job) => {
					if (job.department !== department) return;
					wrapper.append(createJobCard(job));
				});
			});
		}

		// Get the selected department attribute from the DOM
		let selectedDepartment = wrapper
			.find(".listings")
			.attr("data-department");

		// If the selected department doesn't exist in api, reset its value
		if (
			selectedDepartment &&
			!jobsData.departments.includes(selectedDepartment)
		) {
			console.error("The selected department does not exist in the API.");
			wrapper.find(".listings").html(createJobCard());
			return;
		}

		// If there are departments in data
		if (jobsData.departments.length) {
			// Check if the page contains the job listing element
			if (wrapper.hasClass("element-job-listing")) {
				const listingWrap = wrapper.find(".listings");

				// If no department is selected, display a dropdown for department selection
				if (!selectedDepartment) {
					listingWrap.html(`
						<div class="ch-custom-input filter">
							<select id="department-filter" style="width: auto;">
								<option selected disabled>Team wählen</option>
							</select>
						</div>
						<div class="job-list"></div>
					`);

					// Populate the dropdown with department options
					jobsData.departments.map((department) => {
						wrapper.find(".filter select").append(`
							<option value="${department}">${department}</option>
						`);
					});
				} else {
					// If a department is selected, only display the job list
					listingWrap.html('<div class="job-list"></div>');
				}

				// Display the jobs either for all departments (limited to 4) or for the selected department
				Object.values(jobsData.jobs).forEach((job, index) => {
					if (!selectedDepartment && index > 3) return;
					if (
						selectedDepartment &&
						job.department !== selectedDepartment
					)
						return;
					wrapper.find(".job-list").append(createJobCard(job));
				});
			}
		} else {
			// If there are no jobs, log a message
			console.log("There are no jobs. Contact the admin.");
		}
	})();

	wrapper.on("change", ".filter select", function () {
		const selectedDepartment = $(this).val();
		displayJobsByDepartment(wrapper, selectedDepartment, jobsData);
	});
});
